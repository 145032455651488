var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leaderboard-item",class:[_vm.display]},[_c('div',{staticClass:"profile-cont d-flex align-items-center justify-content-center",class:{
      first : _vm.rank === '1st',
      second : _vm.rank === '2nd',
      third: _vm.rank === '3rd'
    }},[_c('b-img',_vm._b({attrs:{"src":_vm.item.profile_img_url || _vm.item.asset_url,"rounded":"circle"}},'b-img',_vm.imageProps,false))],1),_c('div',{staticClass:"detail-cont",class:{
      first : _vm.rank === '1st',
      second : _vm.rank === '2nd',
      third: _vm.rank === '3rd'
    }},[_c('span',{staticClass:"user-name",attrs:{"title":_vm.item.nickname}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.item.nickname,18))+" ")]),_c('span',{staticClass:"rank"},[_vm._v(" "+_vm._s(_vm.rank)+" ")]),(_vm.showExpPoints)?_c('span',{staticClass:"exp-points"},[_vm._v(" "+_vm._s(_vm.expPoints)+" ")]):_vm._e(),(_vm.showLevel)?_c('span',{staticClass:"level"},[_vm._v(" "+_vm._s(_vm.level)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }