<template>
  <div class="leaderboard-item" :class="[display]">
    <div
      class="profile-cont d-flex align-items-center justify-content-center"
      :class="{
        first : rank === '1st',
        second : rank === '2nd',
        third: rank === '3rd'
      }"
    >
      <b-img
        :src="item.profile_img_url || item.asset_url"
        v-bind="imageProps"
        rounded="circle"
      />
    </div>
    <div
      class="detail-cont"
      :class="{
        first : rank === '1st',
        second : rank === '2nd',
        third: rank === '3rd'
      }"
    >
      <span class="user-name" :title="item.nickname">
        {{ item.nickname | truncate(18) }}
      </span>
      <span class="rank">
        {{ rank }}
      </span>
      <span class="exp-points" v-if="showExpPoints">
        {{ expPoints }}
      </span>
      <span class="level" v-if="showLevel">
        {{ level }}
      </span>
    </div>
  </div>
</template>
<script>
  import FilterMixin from '@/mixins/FilterMixin';
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    props : [
      'type',
      'display',
      'item',
    ],
    data() {
      return {
        imageProps : {
          blankColor : '#777',
          class      : 'prof-img',
        },
      };
    },
    computed : {
      rank() {
        return this.ordinalSuffixOf(this.item.id);
      },
      expPoints() {
        return this.item.exp_points + ' pt';
      },
      level() {
        return 'Lv. ' + this.item.level;
      },
      showExpPoints() {
        return (this.type === 'monthly' || this.type === 'weekly') &&
          [ 'md', 'lg', 'xl' ].indexOf(this.breakpoint) !== -1;
      },
      showLevel() {
        return this.type === 'all' &&
          [ 'md', 'lg', 'xl' ].indexOf(this.breakpoint) !== -1;
      },
    },
    methods : {

      /**
       * Ordinal Suffix
       * @param i
       * @return {string}
       */
      ordinalSuffixOf(i) {
        const j = i % 10,
              k = i % 100;
        if (j === 1 && k !== 11)
          return i + 'st';
        
        if (j === 2 && k !== 12)
          return i + 'nd';
        
        if (j === 3 && k !== 13)
          return i + 'rd';
        
        return i + 'th';
      },
    },
    mixins : [
      FilterMixin,
      BreakpointMixin,
    ],
  };

</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .leaderboard-item {
    position: relative;
    min-height: 2.25em;//2em;
    margin: 0.125em 0;

    .profile-cont,
    .detail-cont {
      position:absolute;
      background: $primary-color;

      &.first {
        background: $gold;
      }
      &.second {
        background: $silver;
      }
      &.third {
        background: $bronze;
      }
    }

    .profile-cont {
      height: 2.2em;
      width: 2.2em;
      border-radius: 2em;
      z-index: 999;

      .prof-img {
        height: 1.5em;
        width: 1.5em;
        background-color: #fff;
      }
    }

    .detail-cont {
      height: 1.75em;
      width: 100%;
      margin: 0.25em 0;
      padding-left: 2.3em;//2.125em;
      border-radius: 1em 0.25em 0.25em 1em;
      z-index: 998;

      .user-name,
      .exp-points,
      .level,
      .rank {
        font-size: 0.8em; //0.5em;
        line-height: 2; //3;
        font-weight: bold;
        color: #fff;
      }

      .user-name {
        float: left;
      }

      .exp-points,
      .level {
        float: right;
      }

      .rank {
        float: right;
        height: 3em;
        min-width: 4em;
        padding-right: 1em;
        text-align: right;
      }
    }
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {

  }

  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {

  }

  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .leaderboard-item {
      &.page {
        min-height: 4em;
        margin: 0.125em 0;

        .profile-cont {
          height: 4em;
          width: 4em;
          border-radius: 4em;

          .prof-img {
            height: 3em;
            width: 3em;
          }
        }

        .detail-cont {
          height: 3em;
          width: 100%;
          margin: 0.5em 0;
          padding-left: 4.25em;
          border-radius: 2em 0.5em 0.5em 2em;

          .user-name,
          .level,
          .exp-points,
          .rank {
            font-size: 1.25em; //1em;
            line-height: 3;
          }

          .rank {
            height: 3em;
            padding-right: 1em;
          }
        }
      }
    }
  }

  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {

  }

  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .leaderboard-item {
      &.page {
        .detail-cont {
          .rank {
            min-width: 8em;
          }
        }
      }
    }
  }

</style>